/** @format */

import store from '@/store'
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators'
import SelectOption from '@/models/interface/SelectOption'
import Api from '@/models/Api'
import InsertionOrderItem from '@/models/InsertionOrderItem'
import PaginateOptions from '@/models/interface/PaginateOptions'

@Module({
  dynamic: true,
  store,
  namespaced: true,
  name: 'insertion_order_item',
})
export default class InsertionOrderItemModule extends VuexModule {
  public data: InsertionOrderItem[] = []

  public options: SelectOption[] = []

  public synchronized: boolean = false

  public get api() {
    return new Api(false)
  }

  @Mutation
  public setSynchronized(status: boolean) {
    this.synchronized = status
  }

  @Mutation
  public update(
    insertion_order_items: InsertionOrderItem | InsertionOrderItem[],
  ) {
    let data: Array<InsertionOrderItem> = []
    if (insertion_order_items instanceof InsertionOrderItem) {
      data.push(insertion_order_items)
    } else {
      data = insertion_order_items
    }

    let result = this.data

    data.forEach((insertion_order_item: InsertionOrderItem) => {
      let found: boolean = false
      result = result.map((obj: InsertionOrderItem) => {
        if (obj.id == insertion_order_item.id) {
          found = true
          return insertion_order_item
        }
        return obj
      })

      if (!found) {
        result.push(insertion_order_item)
      }
    })

    this.data = result
  }

  @Mutation
  public delete(
    insertion_order_items: InsertionOrderItem | InsertionOrderItem[],
  ) {
    let data: Array<InsertionOrderItem> = []
    if (insertion_order_items instanceof InsertionOrderItem) {
      data.push(insertion_order_items)
    } else {
      data = insertion_order_items
    }

    let result = this.data

    data.forEach((insertion_order_item: InsertionOrderItem) => {
      result = result.filter((obj: InsertionOrderItem) => obj.id != insertion_order_item.id)
    })

    this.data = result
  }

  @Action
  public async find(id: string): Promise<InsertionOrderItem | null> {
    return new Promise(resolve => {
      const o = this.data.find(
        insertion_order_item => insertion_order_item.id === id,
      )

      if (o instanceof InsertionOrderItem) {
        resolve(o)
      } else {
        return this.api
          .get(`insertion_order_item/${id}`)
          .then(response => {
            // Parse & cache data
            const data = InsertionOrderItem.toObject(
              response.data.result.insertion_order_item,
            )

            if (data instanceof InsertionOrderItem) {
              resolve(data)
            }
          })
          .catch(() => {
            resolve(null)
          })
      }
    })
  }

  @Action
  public async paginate(options: PaginateOptions) {
    return this.api
      .get('insertion_order_items/paginate', options)
      .then(response => {
        // Parse & cache data
        const data = InsertionOrderItem.toObjectList(
          response.data.result.insertion_order_items,
        )

        return {
          records: response.data.result.records,
          data,
        }
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }

  @Action
  public async searchOptions(query: any) {
    return this.api
      .get('insertion_order/item/search/option', query)
      .then(response => SelectOption.toObjectList(response.data.result.options))
      .catch(() => [])
  }
}
