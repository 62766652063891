











import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class InsertionOrderHeaderProperty extends Vue {
  @Prop()
  public label!: string;

  @Prop()
  public value!: string;
}
