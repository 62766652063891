












































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  Component, Vue, Prop, Watch, Ref,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import InsertionOrder from '@/models/InsertionOrder'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import InsertionOrderItem from '@/models/InsertionOrderItem'
import WebMessage from '@/models/WebMessage'
import ViewModel from '@/models/ViewModel'
// @ts-ignore
import CKEditor from '@ckeditor/ckeditor5-vue2'
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { FormWizard, TabContent } from 'vue-form-wizard'
import FormInput from '@/components/FormInput/FormInput.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import TermsPicker from '@/components/TermsPicker/TermsPicker.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import SelectOption from '@/models/interface/SelectOption'
import FormButtons from '@/pages/MediaOcean/Invoice/FormButtons.vue'
import MediaPlan from '@/models/MediaPlan'
import { integerMask, currencyMask } from '@/models/interface/Masks'
import Terms from '../../models/Terms'
import Company from '../../models/Company'
import User from '../../models/User'
import InsertionOrderPrintView from './Components/InsertionOrderPrintView.vue'

// @ts-ignore
@Component({
  // @ts-ignore
  components: {
    Widget,
    DatePicker,
    SelectPicker,
    InsertionOrderPrintView,
    ckeditor: CKEditor.component,
    FormWizard,
    TabContent,
    FormInput,
    UserPicker,
    CompanyPicker,
    IconAction,
    TermsPicker,
    FormButtons,
  },
})
export default class InsertionOrderEdit extends ViewModel {
  @Ref() readonly formWizard: any

  @Prop()
  public id!: string

  public loading: boolean = false

  public editor = ClassicEditor

  public busy = true

  public title = 'Create IO'

  public action: string = 'delete'

  public modal: boolean = false

  public insertion_order: InsertionOrder = new InsertionOrder()

  public batch_item: InsertionOrderItem = new InsertionOrderItem()

  public item: InsertionOrderItem | null = null

  public selected: string[] = []

  public terms_template: any = null

  public has_guarantee: boolean = false

  public panel: any = {
    edit_terms: false,
  }

  public insertion_order_columns: any = [
    {
      name: 'Model',
      value: 'model',
    },
    {
      name: 'Net Rate',
      value: 'net_rate',
    },
    {
      name: 'Net Cost',
      value: 'net_cost',
    },
    {
      name: 'Gross Rate',
      value: 'gross_rate',
    },
    {
      name: 'Gross Cost',
      value: 'gross_cost',
    },
  ]

  public insertion_order_types: any = [
    {
      name: 'Advertiser',
      value: 'advertiser',
    },
    {
      name: 'Publisher',
      value: 'publisher',
    },
  ]

  public insertion_order_models: any = [
    {
      name: 'CPM',
      value: 'cpm',
    },
    {
      name: 'CPD',
      value: 'cpd',
    },
  ]

  private insertion_order_order_types: any = [
    {
      name: 'Cash',
      value: 'Cash',
    },
    {
      name: 'Trade',
      value: 'Trade',
    },
  ]

  public target_options: SelectOption[] = [
    new SelectOption('People (P)', 'P'),
    new SelectOption('Male (M)', 'M'),
    new SelectOption('Female (F)', 'F'),
    new SelectOption('Adults (AD)', 'AD'),
  ]

  public age_low_options: SelectOption[] = [
    new SelectOption('0', '0'),
    new SelectOption('2', '2'),
    new SelectOption('6', '6'),
    new SelectOption('12', '12'),
    new SelectOption('18', '18'),
    new SelectOption('21', '21'),
    new SelectOption('25', '25'),
    new SelectOption('35', '35'),
    new SelectOption('45', '45'),
    new SelectOption('50', '50'),
    new SelectOption('55', '55'),
    new SelectOption('65', '65'),
  ]

  public age_high_options: SelectOption[] = [
    new SelectOption('5', '5'),
    new SelectOption('11', '11'),
    new SelectOption('17', '17'),
    new SelectOption('20', '20'),
    new SelectOption('24', '24'),
    new SelectOption('34', '34'),
    new SelectOption('44', '44'),
    new SelectOption('49', '49'),
    new SelectOption('54', '54'),
    new SelectOption('64', '64'),
    new SelectOption('99+', '+'),
  ]

  private get masks() {
    return {
      integerMask,
      currencyMask,
    }
  }

  public step: number = 1

  public local_terms = ''

  public editTerms() {
    this.local_terms = this.insertion_order.terms
    this.panel.edit_terms = true
  }

  public updateTerms() {
    this.panel.edit_terms = false
  }

  public cancelTerms() {
    this.insertion_order.terms = this.local_terms
    this.panel.edit_terms = false
  }

  public onSubmit() {
    this.loading = true
    const { query } = this.$route

    this.insertion_order
      // @ts-ignore
      .save(query.order_cash, query.order_trade)
      .then(response => {
        this.loading = false
        if (response.status == 200) {
          this.$router.push({ name: 'InsertionOrderHome' })
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  public cancel() {
    this.$router.push({ name: 'InsertionOrderHome' })
  }

  public batchEdit() {
    this.action = 'batch'
    this.modal = true
  }

  public deleteItem(item: InsertionOrderItem) {
    this.item = item
    this.action = 'delete'
    this.modal = true
  }

  public isEnabled(name: string) {
    return this.selected.includes(name)
  }

  public modalConfirm() {
    if (this.action === 'batch') {
      const items = InsertionOrderItem.toObjectList(this.insertion_order.items)
      this.selected.forEach(prop => {
        items.forEach(item => {
          // @ts-ignore
          if (prop.includes('demographic')) {
            const path = prop.split('.')
            Vue.set(
              // @ts-ignore
              item[path[0]][path[1]],
              path[2],
              // @ts-ignore
              this.batch_item[path[0]][path[1]][path[2]],
            )
          } else if (prop.includes('media_ocean') && prop !== 'media_ocean_impressions') {
            const path = prop.split('.')
            // @ts-ignore
            Vue.set(item[path[0]], path[1], this.batch_item[path[0]][path[1]])
          } else {
            // @ts-ignore
            Vue.set(item, prop, this.batch_item[prop])
          }
        })
      })

      this.insertion_order.reloadItems(items)
    } else if (this.action === 'delete' && this.item) {
      this.insertion_order.removeItem(this.item.number)
    }
  }

  public updateProgress(prevIndex: number, nextIndex: number) {
    if (nextIndex >= 0) {
      Vue.set(this, 'step', nextIndex + 1)
    }

    if (
      (nextIndex === 3 && this.insertion_order.is_media_ocean_io)
      || (nextIndex === 2 && !this.insertion_order.is_media_ocean_io)
    ) {
      if (this.insertion_order.owner_company_id && !this.insertion_order.owner_company) {
        Company.get(this.insertion_order.owner_company_id)
      }

      if (this.insertion_order.client_id && !this.insertion_order.client) {
        Company.get(this.insertion_order.client_id)
      }

      if (this.insertion_order.owner_id && !this.insertion_order.owner) {
        User.get(this.insertion_order.owner_id)
      }

      this.insertion_order.items = this.insertion_order.items.map(i => {
        if (!i.media_ocean.demographic.target) {
          i.media_ocean.demographic.target = this.insertion_order.media_ocean.demographic.target
        }

        if (!i.media_ocean.demographic.age_low) {
          i.media_ocean.demographic.age_low = this.insertion_order.media_ocean.demographic.age_low
        }

        if (!i.media_ocean.demographic.age_high) {
          i.media_ocean.demographic.age_high = this.insertion_order.media_ocean.demographic.age_high
        }

        return i
      })
    }
  }

  public setAccountManager() {
    if (this.insertion_order && !this.insertion_order.account_manager_id) {
      this.insertion_order.account_manager_id = this.user.id
    }
  }

  public created() {
    const { query } = this.$route

    if (this.id) {
      this.loading = true
      InsertionOrder.get(this.id).then(o => {
        if (o instanceof InsertionOrder) {
          this.insertion_order = o

          this.setAccountManager()

          /* if (this.insertion_order.external_document_id) {
            this.$router.push('/app/insertion_order/' + this.id + '/view')
            WebMessage.error(
              'This IO was sent for signature, please cancel it first before editing it.'
            )
          } else {

          } */
          this.title = 'Edit IO'

          this.loading = false
          this.busy = false
          this.has_guarantee = this.insertion_order.items.some(item => item.guaranteed > 0)

          setTimeout(() => {
            // @ts-ignore
            this.formWizard.activateAll()
          }, 1000)
        } else {
          return this.newIO()
        }
      })
    } else if (query.from && typeof query.from === 'string') {
      InsertionOrder.get(query.from).then(o => {
        if (o instanceof InsertionOrder) {
          this.insertion_order = Object.assign(new InsertionOrder(), o)

          this.insertion_order.id = null
          this.setAccountManager()

          const items: Array<InsertionOrderItem> = []
          o.items.forEach((item: InsertionOrderItem) => {
            const i = Object.assign(new InsertionOrderItem(), item)
            i.id = null
            items.push(i)
          })
          this.insertion_order.items = items

          this.has_guarantee = this.insertion_order.items.some(item => item.guaranteed > 0)

          this.loading = false
          this.busy = false
        } else {
          return this.newIO()
        }
      })
    } else if (query.media_plan && typeof query.media_plan === 'string') {
      let promises = [MediaPlan.find(query.media_plan)]
      if (query.order_cash || query.order_trade) {
        promises.push(MediaPlan.processOrderFiles(query.order_cash, query.order_trade))
      }
      Promise.allSettled(promises).then((responses: any) => {
        this.insertion_order = InsertionOrder.fromMediaPlan(
          responses[0].value,
          responses[1]?.value.data.result ?? null,
        )

        this.loading = false
        this.busy = false
      })
    } else {
      this.newIO()
    }
  }

  public newIO() {
    this.insertion_order.addItem()
    this.setAccountManager()

    this.loading = false
    this.busy = false
  }
}
