/** @format */

import InsertionOrderItemModule from '@/store/model/InsertionOrderItemModule'
import store from '@/store'
import { getModule } from 'vuex-module-decorators'
import moment from 'moment'
import InsertionOrder from './InsertionOrder'
import MediaPlanItem from './MediaPlanItem'
import MediaPlan from './MediaPlan'
import MediaPackage from './MediaPackage'

export default class InsertionOrderItem {
  public id: string | null = null

  public name: string = ''

  public media_package_id: string | null = null

  public media_package: MediaPackage | null = null

  public start_at: string = ''

  public end_at: string = ''

  public insertion_order_id: string | null = null

  public impressions: number = 0

  public total_impressions: number = 0

  public guaranteed: number = 0

  public cost: number = 0

  public model: string = 'cpm'

  public number: number = 1

  public notes: string | null = null

  public _ui_id = Math.floor(Math.random() * Math.floor(500))

  public visible: boolean = false

  public agency_commission: number = 0

  public metrics: any = {}

  public media_ocean: any = {
    program_name: '',
    order_type: 'Cash',
    demographic: {
      target: '',
      age_high: '',
      age_low: '',
    },
  }

  public get video_copy_id() {
    if (
      !this.media_ocean.demographic.target
      || !this.media_ocean.demographic.age_low
      || !this.media_ocean.demographic.age_high
    ) {
      return null
    }
    let ret = `I${this.media_ocean.demographic.target}${this.media_ocean.demographic.age_low}${this.media_ocean.demographic.age_high}`
    ret += `=${(this.impressions / 100).toFixed(0).replaceAll('.', '')}`

    return ret
  }

  public set video_copy_id(video_copy_id) {
    // @ts-ignore
  }

  public get key() {
    return this.id ?? this._ui_id
  }

  public get media_ocean_impressions(): number {
    return this.impressions
  }

  public set media_ocean_impressions(impressions: number) {
    if (this.cost) {
      if (impressions) this.cost = this.total / (impressions / 1000)
      else this.cost = this.total / (1 / 1000)
    }
    this.impressions = impressions
  }

  public get total() {
    return (this.impressions * this.cost) / 1000
  }

  public set total(cost: number) {
    this.cost = (cost / this.impressions) * 1000
  }

  public get gross_cost() {
    return this.cost / (1 - this.agency_commission / 100)
  }

  public set gross_cost(gross_cost: number) {
    // @ts-ignore
  }

  public get gross_total() {
    return Number((this.total / (1 - this.agency_commission / 100)).toFixed(2))
  }

  public set gross_total(gross_total: number) {
    this.total = gross_total * (1 - this.agency_commission / 100)
  }

  public get short_name() {
    const limit = 50
    return this.name.length > limit ? `${this.name.substr(0, limit)}...` : this.name
  }

  public get topISCI(): string {
    if (!this.metrics.isci_codes) return ''
    return this.metrics.isci_codes.sort((a: any, b: any) => b.impressions - a.impressions)[0]
      .isci_code
  }

  public static toObject(data: any, cache: boolean = true): InsertionOrderItem {
    const insertion_order_item = new InsertionOrderItem()

    if (data.agency_commission) insertion_order_item.agency_commission = data.agency_commission
    insertion_order_item.id = data.id
    insertion_order_item.name = data.name
    insertion_order_item.start_at = data.start_at
    insertion_order_item.end_at = data.end_at
    insertion_order_item.insertion_order_id = data.insertion_order_id
    insertion_order_item.impressions = data.impressions
    insertion_order_item.guaranteed = data.guaranteed
    insertion_order_item.cost = data.cost
    insertion_order_item.model = data.model
    insertion_order_item.number = data.number
    insertion_order_item.notes = data.notes
    insertion_order_item.total_impressions = data.total_impressions
    insertion_order_item.metrics = data.metrics
    insertion_order_item.media_package_id = data.media_package_id
    insertion_order_item.media_package = data.media_package

    // Media Ocean Meta data
    if (data.media_ocean) {
      insertion_order_item.media_ocean.program_name = data.media_ocean.program_name ?? ''
      insertion_order_item.media_ocean.order_type = data.media_ocean.order_type ?? 'Cash'
      if (data.media_ocean.demographic) {
        insertion_order_item.media_ocean.demographic.target = data.media_ocean.demographic.target ?? ''
        insertion_order_item.media_ocean.demographic.age_high = data.media_ocean.demographic.age_high ?? ''
        insertion_order_item.media_ocean.demographic.age_low = data.media_ocean.demographic.age_low ?? ''
      }
    }

    if (data.visible) insertion_order_item.visible = data.visible

    //  Cache Object List
    if (cache) InsertionOrderItem.module.update(insertion_order_item)

    return insertion_order_item
  }

  public static fromMediaPlanItem(media_plan: MediaPlan, media_plan_item: MediaPlanItem) {
    const item = new InsertionOrderItem()

    item.name = media_plan_item.name
    item.number = media_plan_item.number
    item.start_at = media_plan_item.start_at
    item.end_at = media_plan_item.end_at
    item.model = media_plan_item.model
    item.agency_commission = media_plan_item.agency_commission
    item.impressions = media_plan_item.impressions
    item.media_package_id = media_plan_item.media_package_id
    item.media_package = media_plan_item.media_package
    if (media_plan.isLinear) item.gross_total = media_plan_item.gross_cost
    else item.cost = media_plan_item.net_rate
    item.notes = media_plan_item.notes

    if (media_plan_item.isLinear) {
      item.media_ocean.program_name = media_plan_item.metadata.program_name
      item.media_ocean.demographic = media_plan.metadata.demo_targets[media_plan_item.metadata.demo_target]
    }

    return item
  }

  public buildRequest() {
    return {
      id: this.id,
      name: this.name,
      media_package_id: this.media_package_id,
      media_ocean: this.media_ocean,
      start_at: this.start_at ? moment(this.start_at).format('YYYY-MM-DD') : null,
      end_at: this.end_at ? moment(this.end_at).format('YYYY-MM-DD') : null,
      insertion_order_id: this.insertion_order_id,
      impressions: this.impressions,
      guaranteed: this.guaranteed,
      cost: this.cost,
      model: this.model,
      number: this.number,
      notes: this.notes,
    }
  }

  public static toObjectList(data: any, cache: boolean = true): InsertionOrderItem[] {
    const insertion_order_items = new Array<InsertionOrderItem>()
    data.forEach((value: any) => {
      const insertion_order_item = InsertionOrderItem.toObject(value, false)
      insertion_order_items.push(insertion_order_item)
    })

    //  Cache Object List
    if (cache) InsertionOrderItem.module.update(insertion_order_items)

    return insertion_order_items
  }

  public toOption() {
    return {
      name: this.name,
      value: this.id,
    }
  }

  /// State Management
  public static get module(): InsertionOrderItemModule {
    if (!store.hasModule('insertion_order_item')) {
      store.registerModule('insertion_order_item', InsertionOrderItemModule)
    }

    return getModule(InsertionOrderItemModule)
  }

  public static find(id: string): InsertionOrderItem | null {
    const o = InsertionOrderItem.module.data.find(
      insertion_order_item => insertion_order_item.id === id,
    )
    return o instanceof InsertionOrderItem ? o : null
  }

  public static filter(filter: any): InsertionOrderItem[] {
    if (Array.isArray(filter)) {
      return InsertionOrderItem.module.data.filter(
        insertion_order_item => insertion_order_item.id && filter.includes(insertion_order_item.id),
      )
    }
    return InsertionOrderItem.module.data.filter(filter)
  }
}
