







































































import { Component, Prop } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import InsertionOrder from '@/models/InsertionOrder'
import ViewModel from '@/models/ViewModel'
import InsertionOrderHeaderProperty from './Components/InsertionOrderHeaderProperty.vue'

@Component({
  components: {
    Widget,
    InsertionOrderHeaderProperty,
  },
})
export default class InsertionOrderUpload extends ViewModel {
  @Prop()
  public id!: string;

  public loading: boolean = false;

  public insertion_order: InsertionOrder = new InsertionOrder();

  public file: any = null;

  public busy = true;

  public onSubmit() {
    this.loading = true

    this.insertion_order
      .upload(this.file)
      .then(response => {
        this.loading = false
        if (response.status == 200) {
          this.$router.back()
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  public created() {
    if (!this.id) {
      this.$router.push({ name: 'InsertionOrderHome' })
      return
    }

    InsertionOrder.get(this.id).then(o => {
      if (o instanceof InsertionOrder) {
        this.insertion_order = o
      } else {
        this.$router.push({ name: 'InsertionOrderHome' })
        return
      }

      this.busy = false
    })
  }
}
