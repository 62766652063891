










































import { Component, Prop } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import InsertionOrder from '@/models/InsertionOrder'
import ViewModel from '@/models/ViewModel'
import Api from '@/models/Api'
import InsertionOrderPrintView from './Components/InsertionOrderPrintView.vue'

@Component({
  components: {
    Widget,
    InsertionOrderPrintView,
  },
})
export default class InsertionOrderView extends ViewModel {
  @Prop()
  public id!: string

  public busy = true

  public insertion_order: InsertionOrder | null = null

  public download() {
    if (this.insertion_order) {
      const api = new Api()
      api.download(
        `insertion_order/${this.insertion_order.id}/download`,
        `IO${this.insertion_order.number}.pdf`,
      )
    }
  }

  public editInsertionOrder() {
    if (this.insertion_order) this.$router.push(`/app/insertion_order/${this.id}`)
  }

  public created() {
    if (!this.id) {
      this.$router.push({ name: 'InsertionOrderHome' })
      return
    }

    InsertionOrder.get(this.id).then(o => {
      if (o instanceof InsertionOrder) {
        this.insertion_order = o
      } else {
        this.$router.push({ name: 'InsertionOrderHome' })
        return
      }

      this.busy = false
    })
  }
}
