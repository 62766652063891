export default {
  query_settings: {
    company_fields: [
      {
        name: 'client',
        key: 'client_id',
      },
      {
        name: 'agency',
        key: 'agency_id',
        type: 'agency',
      },
    ],
    user_fields: [
      {
        name: 'ae',
        key: 'owner_id',
      },
      {
        name: 'am',
        key: 'account_manager_id',
      },
    ],
    custom_fields: [
      {
        name: 'type:publisher',
        value: 'type:publisher',
      },
      {
        name: 'type:advertiser',
        value: 'type:advertiser',
      },
      {
        name: 'is:draft',
        value: 'is:draft',
      },
      {
        name: 'is:pending',
        value: 'is:pending',
      },
      {
        name: 'is:signed',
        value: 'is:signed',
      },
      {
        name: 'is:media_ocean',
        value: 'is:media_ocean',
      },
      {
        name: 'is_not:media_ocean',
        value: 'is_not:media_ocean',
      },
    ],
  },

  slide_options: [
    { name: 'Overview', value: 'overview' },
    { name: 'Items Details', value: 'details' },
    { name: 'Distribution', value: 'distribution' },
    { name: 'Media Placement', value: 'placements' },
  ],

  download_options: [
    { name: 'Delivery Report', value: 'donwloadReport' },
    { name: 'Export IOs', value: 'export' },
  ],

  map_options: [
    { name: 'DMA', value: 'dma' },
    { name: 'State', value: 'state' },
  ],

  logo_options: [
    {
      name: 'CBS Sports Network',
      value: 'CBS Sports Network',
    },
    {
      name: 'ESPN',
      value: 'ESPN',
    },
    {
      name: 'ESPN 2',
      value: 'ESPN 2',
    },
    {
      name: 'ESPN 3',
      value: 'ESPN 3',
    },
    {
      name: 'ESPN News',
      value: 'ESPN News',
    },
    {
      name: 'Eleven Sports',
      value: 'Eleven Sports',
    },
    {
      name: 'FS1',
      value: 'FS1',
    },
    {
      name: 'FS2',
      value: 'FS2',
    },
    {
      name: 'Fox Sports Network',
      value: 'Fox Sports Network',
    },
    {
      name: 'MLB',
      value: 'MLB',
    },
    {
      name: 'MLB Network',
      value: 'MLB Network',
    },
    {
      name: 'MLS (Soccer)',
      value: 'MLS (Soccer)',
    },
    {
      name: 'NBA',
      value: 'NBA',
    },
    {
      name: 'NBC Sports',
      value: 'NBC Sports',
    },
    {
      name: 'NCAA - ACC Network',
      value: 'NCAA - ACC Network',
    },
    {
      name: 'NCAA - Big 10 Network',
      value: 'NCAA - Big 10 Network',
    },
    {
      name: 'NCAA - CBS Sports Network',
      value: 'NCAA - CBS Sports Network',
    },
    {
      name: 'NCAA - Longhorn Network',
      value: 'NCAA - Longhorn Network',
    },
    {
      name: 'NCAA - Pac 12',
      value: 'NCAA - Pac 12',
    },
    {
      name: 'NCAA - SEC',
      value: 'NCAA - SEC',
    },
    {
      name: 'NFL',
      value: 'NFL',
    },
    {
      name: 'NFL Network',
      value: 'NFL Network',
    },
    {
      name: 'NHL',
      value: 'NHL',
    },
    {
      name: 'Outdoor Network',
      value: 'Outdoor Network',
    },
    {
      name: 'Outside TV',
      value: 'Outside TV',
    },
    {
      name: 'PGA - Golf Channel',
      value: 'PGA - Golf Channel',
    },
    {
      name: 'Racing America',
      value: 'Racing America',
    },
    {
      name: 'SpartanTV',
      value: 'SpartanTV',
    },
    {
      name: 'Stadium',
      value: 'Stadium',
    },
    {
      name: 'TVG',
      value: 'TVG',
    },
    {
      name: 'TVG2',
      value: 'TVG2',
    },
    {
      name: 'Tennis Channel',
      value: 'Tennis Channel',
    },

    // Legacy
    {
      name: 'BeinSports',
      value: 'BeinSports',
    },
    {
      name: 'Sportsman',
      value: 'Sportsman',
    },
    {
      name: 'ESPN U',
      value: 'ESPN U',
    },
    {
      name: 'World Fishing Network',
      value: 'World Fishing Network',
    },
  ],
}
