















































import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator'

@Component({})
export default class FormButtons extends Vue {
  @Prop({ default: false })
  private loading!: boolean

  @Prop({ default: 1 })
  private activeIndex!: number

  @Prop({ default: false })
  private isLastStep!: boolean
}
