







































































































































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import InsertionOrder from '@/models/InsertionOrder'
import Api from '@/models/Api'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import WebMessage from '@/models/WebMessage'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import DataTable from '@/components/DataTable/index.vue'
import fields from './fields'
import options from './options'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    SelectPicker,
    DataTable,
  },
})
export default class InsertionOrderHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public insertion_order: InsertionOrder = new InsertionOrder()

  public action: string = 'delete'

  public modal: boolean = false

  public records: number = 0

  public loading: boolean = false

  public query: string[] = []

  public selected: string[] = []

  public map_mode: string = 'dma'

  public logos: string[] = []

  public fieldFilters: any = {}

  public get options() {
    return options
  }

  public slides: string[] = [
    // 'cover', // On by default
    'overview',
    'details',
    'distribution',
    'placements',
    // 'outro', // On by default
  ]

  public appendix: boolean = false

  public fields: Array<any> = []

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public rows(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    return InsertionOrder.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public createInsertionOrder() {
    this.$router.push('/app/insertion_order')
  }

  public editInsertionOrder(insertion_order: InsertionOrder) {
    this.$router.push(`/app/insertion_order/${insertion_order.id}`)
  }

  public cloneInsertionOrder(insertion_order: InsertionOrder) {
    this.$router.push(`/app/insertion_order?from=${insertion_order.id}`)
  }

  public viewInsertionOrder(insertion_order: InsertionOrder) {
    this.$router.push(`/app/insertion_order/${insertion_order.id}/view`)
  }

  public uploadInsertionOrder(insertion_order: InsertionOrder) {
    this.$router.push(`/app/insertion_order/${insertion_order.id}/upload`)
  }

  public confirmSend(insertion_order: InsertionOrder) {
    this.insertion_order = insertion_order
    this.action = 'send'
    this.modal = true
  }

  public sendInsertionOrder() {
    if (this.insertion_order.id) {
      this.insertion_order.send().then(() => {
        this.dataTable.refresh()
      })
    }
  }

  public confirmCancel(insertion_order: InsertionOrder) {
    this.insertion_order = insertion_order
    this.action = 'cancel'
    this.modal = true
  }

  public cancelInsertionOrder() {
    if (this.insertion_order.id) {
      this.insertion_order.cancel().then(() => {
        this.dataTable.refresh()
      })
    }
  }

  public confirmReminder(insertion_order: InsertionOrder) {
    this.insertion_order = insertion_order
    this.action = 'reminder'
    this.modal = true
  }

  public reminderInsertionOrder() {
    if (this.insertion_order.id) {
      this.insertion_order.send().then(() => {
        this.dataTable.refresh()
      })
    }
  }

  public confirmDelete(insertion_order: InsertionOrder) {
    this.insertion_order = insertion_order
    this.action = 'delete'
    this.modal = true
  }

  public deleteInsertionOrder() {
    if (this.insertion_order.id) {
      this.insertion_order.delete().then(() => {
        this.dataTable.refresh()
      })
    }
  }

  public download(insertion_order: InsertionOrder) {
    const api = new Api()
    api.download(
      `insertion_order/${insertion_order.id}/download/io`,
      `IO${insertion_order.number}.pdf`,
    )
  }

  public downloadCashOrder(insertion_order: InsertionOrder) {
    const api = new Api()
    api.download(
      `insertion_order/${insertion_order.id}/download/order_cash`,
      `OrderCash_IO${insertion_order.number}.pdf`,
    )
  }

  public downloadTradeOrder(insertion_order: InsertionOrder) {
    const api = new Api()
    api.download(
      `insertion_order/${insertion_order.id}/download/order_trade`,
      `OrderTrade_IO${insertion_order.number}.pdf`,
    )
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.refresh()
  }

  public exportData(insertion_order: InsertionOrder) {
    if (insertion_order && typeof insertion_order.id === 'string') { this.selected = [insertion_order.id] }

    this.action = 'donwloadReport'
    this.modal = true
  }

  public modalConfirm() {
    switch (this.action) {
      case 'delete':
        this.deleteInsertionOrder()
        break
      case 'cancel':
        this.cancelInsertionOrder()
        break
      case 'reminder':
        this.reminderInsertionOrder()
        break
      case 'send':
        this.sendInsertionOrder()
        break
      case 'donwloadReport':
        if (this.selected.length === 0) {
          WebMessage.error('Please select at least 1 IO')
          return
        }
        InsertionOrder.getDeliveryReport(
          this.selected,
          this.slides,
          this.appendix,
          this.map_mode,
          this.logos,
        ).then(() => {
          WebMessage.success('Generating Delivery report, do not close this window!')
          this.selected = []
        })
        break
      case 'export':
        if (this.selected.length === 0) {
          WebMessage.error('Please select at least 1 IO')
          return
        }
        InsertionOrder.export(this.selected).then(() => {
          WebMessage.success('Generating Data, do not close this window!')
          this.selected = []
        })
        break
    }
  }

  public get modal_action_button() {
    switch (this.action) {
      case 'delete':
        return 'Delete'
      case 'cancel':
        return 'Cancel IO'
      case 'reminder':
        return 'Send Reminder'
      case 'send':
        return 'Send'
      case 'donwloadReport':
      case 'export':
        return 'Download'
      default:
        return 'Save'
    }
  }

  public get modal_title() {
    switch (this.action) {
      case 'delete':
        return 'Delete IO'
      case 'cancel':
        return 'Cancel IO'
      case 'reminder':
        return 'Send IO Reminder'
      case 'send':
        return 'Send IO'
      case 'donwloadReport':
        return 'Download Delivery Report'
      case 'export':
        return 'Export IOs'
      default:
        return 'Save'
    }
  }

  public mounted() {
    this.fields = fields
  }
}
