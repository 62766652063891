/** @format */

import Api from '@/models/Api'
import store from '@/store'
import InsertionOrderItem from '@/models/InsertionOrderItem'
import Terms from '@/models/Terms'
import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import moment from 'moment'
import InsertionOrderModule from '@/store/model/InsertionOrderModule'
import SystemtModule from '@/store/SystemModule'
import { clone as _clone } from 'lodash'
import PaginateOptions from './interface/PaginateOptions'
import User from './User'
import WebMessage from './WebMessage'
import Company from './Company'
import MediaPlan from './MediaPlan'

export default class InsertionOrder {
  public id: string | null = null

  public name: string = ''

  public type: string = 'advertiser'

  public number: number = 0

  public agency_id: string | null = null

  public client_id: string = ''

  public contact_name: string = ''

  public contact_title: string = ''

  public contact_email: string = ''

  public owner_id: string | null = '92fb454e-7f5e-4fb5-a3d5-6b76756104ab'

  public media_plan_id: string | null = null

  public media_plan: MediaPlan | null = null

  public sales_rep_id: string | null = ''

  public sales_rep: User | null = null

  public account_manager_id: string | null = ''

  public owner_company_id: string | null = null

  public client_signed: boolean = false

  public client_signed_at: string = ''

  public owner_signed: boolean = false

  public owner_signed_at: string = ''

  public terms: string = ''

  public created_at: string = ''

  public updater: number = 0

  public external_document_id: string | null = null

  public view_columns: string[] = ['model', 'net_rate', 'net_cost']

  public pdf_ready: boolean = false

  public media_ocean: any = {
    agency: {
      id: '',
      name: '',
      address_line_1: '',
      address_line_2: '',
      address_line_3: '',
      address_line_4: '',
    },
    station: {
      call_letters: '',
    },
    header: {
      representative: '',
      advertiser_name: '',
      product_name: '',
      agency_estimate_code: '',
      start_date: '',
      end_date: '',
      station_order_number: '',
      station_trade_order_number: '',
      agency_advertiser_code: '',
      agency_product_code: '',
    },
    demographic: {
      target: '',
      age_high: '',
      age_low: '',
    },
    isci_required: false,
    has_cash_order: false,
    has_trade_order: false,
  }

  public get video_copy_id() {
    if (
      !this.media_ocean.demographic.target
      || !this.media_ocean.demographic.age_low
      || !this.media_ocean.demographic.age_high
    ) {
      return null
    }
    const ret = `I${this.media_ocean.demographic.target}${this.media_ocean.demographic.age_low}${this.media_ocean.demographic.age_high}`
    // ret += `=${(this.impressions / 100).toFixed(0).replaceAll(".", "")}`;

    return ret
  }

  public set video_copy_id(video_copy_id) {
    // @ts-ignore
  }

  private _is_media_ocean_io: boolean = false

  public get is_media_ocean_io(): boolean {
    return this._is_media_ocean_io
  }

  public set is_media_ocean_io(value: boolean) {
    this._is_media_ocean_io = value
    if (value && this.agency_commission === 0 && !this.id) {
      this.agency_commission = 15
    } else {
      this.agency_commission = 0
    }
    if (value) {
      this.view_columns = ['model', 'net_rate', 'gross_rate', 'net_cost', 'gross_cost']
    } else {
      this.view_columns = ['model', 'net_rate', 'net_cost']
    }
  }

  private _agency_commission: number = 0

  public get agency_commission() {
    return this._agency_commission
  }

  public set agency_commission(agency_commission: number) {
    this._agency_commission = agency_commission
    this.items.forEach(i => {
      if (i.agency_commission != this.agency_commission) {
        i.agency_commission = this.agency_commission
      }
    })
  }

  public _terms_id: string = ''

  public get terms_id() {
    return this._terms_id
  }

  public set terms_id(value: string) {
    if (value != this._terms_id) {
      if (value) {
        Terms.get(value).then(t => {
          if (t instanceof Terms) this.terms = t.terms
        })
      } else this.terms = ''

      this._terms_id = value
    }
  }

  private _items!: Array<InsertionOrderItem>

  public get items() {
    if (!this._items) {
      const items = InsertionOrderItem.filter(
        (o: InsertionOrderItem) => o.insertion_order_id === this.id,
      ).sort((a, b) => a.number - b.number)
      let count = 1
      items.forEach(i => {
        i.number = count++
      })
      Vue.set(this, '_items', items)
    }
    return this._items
  }

  public set items(items: Array<InsertionOrderItem>) {
    this._items = items
    this.reloadItems()
  }

  private _agency: Company | null = null

  public get agency(): Company | null {
    if (this.agency_id && (!this._agency || this._agency.id != this.agency_id)) {
      this._agency = Company.find(this.agency_id)
    }
    return this._agency
  }

  public set agency(agency: Company | null) {
    this._agency = agency
    this.agency_id = agency ? agency.id : null
  }

  private _client: Company | null = null

  public get client(): Company | null {
    if (this.client_id && (!this._client || this._client.id != this.client_id)) {
      this._client = Company.find(this.client_id)
    }
    return this._client
  }

  public set client(client: Company | null) {
    this._client = client
    this.client_id = client && client.id ? client.id : ''
  }

  private _owner: User | null = null

  public get owner(): User | null {
    if (!this._owner || this._owner.id != this.owner_id) {
      if (!this.owner_id) {
        const system = getModule(SystemtModule)
        if (system.user) this.owner_id = system.user.id
      }
      if (this.owner_id) this._owner = User.find(this.owner_id)
    }
    return this._owner
  }

  public set owner(owner: User | null) {
    this._owner = owner
    this.owner_id = owner ? owner.id : null
  }

  private _account_manager: User | null = null

  public get account_manager(): User | null {
    if (!this._account_manager || this._account_manager.id != this.account_manager_id) {
      if (this.account_manager_id) this._account_manager = User.find(this.account_manager_id)
    }
    return this._account_manager
  }

  public set account_manager(account_manager: User | null) {
    this._account_manager = account_manager
    this.account_manager_id = account_manager ? account_manager.id : null
  }

  private _owner_company: Company | null = null

  public get owner_company(): Company | null {
    if (!this._owner_company || this._owner_company.id != this.owner_company_id) {
      if (!this.owner_company_id) {
        const system = getModule(SystemtModule)
        if (system.user) this.owner_company_id = system.user.company_id
      }
      if (this.owner_company_id) this._owner_company = Company.find(this.owner_company_id)
    }
    return this._owner_company
  }

  public get hasGuaranteed() {
    return this.items.some((o: InsertionOrderItem) => o.guaranteed > 0)
  }

  public get hasNotes() {
    return this.items.some((o: InsertionOrderItem) => o.notes)
  }

  public addItem(item?: InsertionOrderItem) {
    if (!item) item = new InsertionOrderItem()
    if (!this._items) {
      Vue.set(this, '_items', new Array<InsertionOrderItem>())
    }
    item.number = this._items.length + 1
    item.model = 'cpm'
    item.guaranteed = 0
    item.cost = 0
    item.impressions = 0
    item.agency_commission = this.agency_commission
    item.visible = true
    item.media_ocean.demographic.target = this.media_ocean.demographic.target
    item.media_ocean.demographic.age_high = this.media_ocean.demographic.age_high
    item.media_ocean.demographic.age_low = this.media_ocean.demographic.age_low
    this._items.push(item)
    this.reloadItems()
  }

  public clone(number: number) {
    let item = _clone(this._items.find(i => i.number === number))
    item = InsertionOrderItem.toObject(item)
    item.number = this._items.length + 1
    item.id = null
    item.visible = true
    this._items.push(item)
    this.reloadItems()
  }

  public removeItem(number: number) {
    if (!this._items) {
      Vue.set(this, '_items', new Array<InsertionOrderItem>())
    }

    const items = this.items.filter(i => i.number != number)

    let count = 1
    items.forEach(i => {
      i.number = count++
    })

    this.reloadItems(items)
  }

  public reloadItems(items: InsertionOrderItem[] | null = null) {
    Vue.set(this, '_items', InsertionOrderItem.toObjectList(items ?? this._items, false))
  }

  public moveItemUp(number: number) {
    if (number == 1) return

    this._items[number - 1].number--
    this._items[number - 2].number++

    this._items.sort((a, b) => a.number - b.number)
  }

  public moveItemDown(number: number) {
    if (number == this._items.length) return

    this._items[number - 1].number++
    this._items[number].number--

    this._items.sort((a, b) => a.number - b.number)
  }

  public get start_at() {
    let ret: any = null

    this.items.forEach((item: InsertionOrderItem) => {
      if (
        (ret == null && item.start_at)
        || (ret != null && item.start_at && ret.isAfter(moment(item.start_at)))
      ) {
        ret = moment(item.start_at)
      }
    })

    return ret ? ret.format('YYYY-MM-DD') : null
  }

  public get end_at() {
    let ret: any = null

    this.items.forEach((item: InsertionOrderItem) => {
      if (
        (ret == null && item.end_at)
        || (ret != null && item.end_at && ret.isBefore(moment(item.end_at)))
      ) {
        ret = moment(item.end_at)
      }
    })

    return ret ? ret.format('YYYY-MM-DD') : null
  }

  public get impressions() {
    return this.items.reduce(
      (carry, item) =>
        (typeof item.impressions === 'string'
          ? parseInt(item.impressions) + carry
          : item.impressions + carry),
      0,
    )
  }

  public get guaranteed() {
    return this.items.reduce((carry, item) => item.guaranteed + carry, 0)
  }

  public get total() {
    return this.items.reduce((carry, item) => item.total + carry, 0)
  }

  public get cost() {
    return (this.total / this.impressions) * 1000
  }

  public get gross_cost() {
    return this.cost / (1 - this.agency_commission / 100)
  }

  public get gross_total() {
    return this.total / (1 - this.agency_commission / 100)
  }

  public get is_registered() {
    return this.external_document_id != null
  }

  public get model() {
    return this.items.reduce(
      (carry, item) => (item.model !== carry ? '-' : carry),
      this.items[0].model,
    )
  }

  public get status_human(): string {
    if (!this.pdf_ready) {
      return 'Generating PDF'
    }
    if (!this.is_registered && !this.client_signed && !this.owner_signed) {
      return 'Draft'
    }
    if (this.client_signed && this.owner_signed) {
      return 'Signed'
    }
    return 'Pending'
  }

  public get status_color(): string {
    if (!this.is_registered && !this.client_signed && !this.owner_signed) {
      return 'secondary'
    }
    if (this.client_signed && this.owner_signed) {
      return 'success'
    }
    if (!this.client_signed && !this.owner_signed) {
      return 'danger'
    }
    return 'warning'
  }

  public buildRequest() {
    const instance_id = getModule(SystemtModule)._uuid

    const items: any = []
    this.items.forEach(i => {
      items.push(i.buildRequest())
    })
    const ret = {
      name: this.name,
      type: this.type,
      number: this.number,
      client_id: this.client_id,
      agency_id: this.agency_id,
      contact_name: this.contact_name,
      contact_title: this.contact_title,
      contact_email: this.contact_email,
      start_at: this.start_at,
      end_at: this.end_at,
      items,
      terms_id: this.terms_id === 'custom' || this.terms_id === '' ? null : this.terms_id,
      terms: this.terms,
      owner_id: this.owner_id,
      sales_rep_id: this.sales_rep_id,
      agency_commission: this.agency_commission,
      view_columns: this.view_columns,
      is_media_ocean_io: this.is_media_ocean_io,
      media_ocean: this.media_ocean,
      account_manager_id: this.account_manager_id,
      media_plan_id: this.media_plan_id,
      instance_id,
    }

    return ret
  }

  public save(order_cash: string | null = null, order_trade: string | null = null) {
    const api = new Api()

    if (this.id) {
      return api
        .put(`insertion_order/${this.id}`, this.buildRequest())
        .then(this.onSave)
        .catch(this.onError)
    }
    return api
      .post('insertion_order', { ...this.buildRequest(), order_cash, order_trade })
      .then(this.onSave)
      .catch(this.onError)
  }

  public upload(file: any) {
    const api = new Api()
    return api
      .form(`insertion_order/${this.id}/upload`, { file })
      .then(this.onSave)
      .catch(this.onError)
  }

  public send() {
    const api = new Api()

    return api
      .post(`insertion_order/${this.id}/send`, {})
      .then(this.onSend)
      .catch(this.onError)
  }

  public cancel() {
    const api = new Api()

    return api
      .delete(`insertion_order/${this.id}/send`, {})
      .then(this.onSave)
      .catch(this.onError)
  }

  public delete() {
    const api = new Api()

    return api
      .delete(`insertion_order/${this.id}`, {})
      .then(this.onDelete)
      .catch(this.onError)
  }

  private onSend(response: any) {
    const insertion_order = InsertionOrder.toObject(response.data.result.insertion_order)

    WebMessage.success(`IO #${insertion_order.number} "${insertion_order.name}" sent!`)

    return response
  }

  private onSave(response: any) {
    const insertion_order = InsertionOrder.toObject(response.data.result.insertion_order)

    WebMessage.success(`IO #${insertion_order.number} "${insertion_order.name}" saved!`)

    return response
  }

  private onDelete(response: any) {
    const insertion_orders = InsertionOrder.filter(response.data.result.deleted)

    let message

    if (insertion_orders.length == 1) {
      message = `IO #${insertion_orders[0].number} "${insertion_orders[0].name}" deleted!`
    } else {
      message = 'IOs deleted!'
    }

    WebMessage.success(message)

    InsertionOrder.module.delete(insertion_orders)

    return response
  }

  private onError(error: any) {
    return error
  }

  public static fromMediaPlan(media_plan: MediaPlan, order_data: any): InsertionOrder {
    const io = new InsertionOrder()

    // Assign MediaPlan data
    io.media_plan_id = media_plan.id
    io.name = media_plan.name
    io.is_media_ocean_io = media_plan.isLinear
    io.agency = media_plan.agency
    io.agency_id = media_plan.agency_id
    io.client = media_plan.advertiser
    io.client_id = media_plan.advertiser_id
    io.owner = media_plan.sales_management
    io.owner_id = media_plan.sales_management_id
    io.sales_rep_id = media_plan.sales_rep_id
    io.sales_rep = media_plan.sales_rep
    io.account_manager = media_plan.account_manager
    io.account_manager_id = media_plan.account_manager_id
    io.contact_name = media_plan.contact_name
    io.contact_title = media_plan.contact_title
    io.contact_email = media_plan.contact_email

    io.items = media_plan.line_items.map(line_item =>
      InsertionOrderItem.fromMediaPlanItem(media_plan, line_item))

    if (media_plan.isLinear) {
      io.media_ocean.station.call_letters = media_plan.metadata.station
      io.media_ocean.demographic = media_plan.metadata.demo_targets[0]
      io.media_ocean.header.start_date = moment(media_plan.start_at).format('YYMMDD')
      io.media_ocean.header.end_date = moment(media_plan.end_at).format('YYMMDD')
    }

    if (!order_data.order_cash && !order_data.order_tarde) return io

    let base_type = order_data.order_cash ? 'order_cash' : 'order_trade'
    let order = order_data[base_type]

    // Assign Order data
    if (order.agency_name) {
      io.media_ocean.agency.name = order.agency_name
    }
    if (order.idb) {
      io.media_ocean.agency.id = order.idb
    }
    if (order.agency_address) {
      if (Array.isArray(order.agency_address)) {
        for (let i = 0; i < order.agency_address.length && i <= 3; i++) {
          io.media_ocean.agency[`address_line_${i + 1}`] = order.agency_address[i]
        }
      } else {
        io.media_ocean.agency.address_line_1 = order.agency_address
      }
    }
    if (order.client_name) {
      io.media_ocean.header.advertiser_name = order.client_name
    }
    if (order.product_name) {
      io.media_ocean.header.product_name = order.product_name
    }
    if (order.estimate_code) {
      io.media_ocean.header.agency_estimate_code = order.estimate_code
    }
    if (order.product_code) {
      io.media_ocean.header.agency_product_code = order.product_code
    }
    if (order.client_code) {
      io.media_ocean.header.agency_advertiser_code = order.client_code
    }
    if (order.buyer_name) {
      io.media_ocean.header.representative = order.buyer_name
    }

    if (base_type === 'order_cash') {
      io.media_ocean.header.station_order_number = order_data.order_cash.order_number
      io.media_ocean.has_cash_order = true
      if (order_data.order_tarde) {
        io.media_ocean.header.station_trade_order_number = order_data.order_tarde.order_number
        io.media_ocean.has_trade_order = true
      }
    } else {
      io.media_ocean.header.station_trade_order_number = order_data.order_tarde.order_number
    }

    return io
  }

  public static toObject(data: any, cache: boolean = true): InsertionOrder {
    const insertion_order = new InsertionOrder()

    insertion_order.id = data.id
    insertion_order.name = data.name
    insertion_order.type = data.type
    insertion_order.number = data.number
    insertion_order.client_id = data.client_id
    insertion_order.agency_id = data.agency_id
    insertion_order.contact_name = data.contact_name
    insertion_order.contact_title = data.contact_title
    insertion_order.contact_email = data.contact_email
    insertion_order.owner_id = data.owner_id
    insertion_order.owner_company_id = data.owner_company_id
    insertion_order.client_signed = data.client_signed
    insertion_order.client_signed_at = data.client_signed_at
    insertion_order.owner_signed = data.owner_signed
    insertion_order.owner_signed_at = data.owner_signed_at
    insertion_order._terms_id = data.terms_id ?? data.terms ? 'custom' : ''
    insertion_order.terms = data.terms
    insertion_order.created_at = data.created_at
    insertion_order.updater = data.updater
    insertion_order.external_document_id = data.external_document_id

    insertion_order.media_plan_id = data.media_plan_id
    insertion_order.media_plan = data.media_plan

    insertion_order.view_columns = data.view_columns
    insertion_order.pdf_ready = data.pdf_ready
    insertion_order.sales_rep_id = data.sales_rep_id
    insertion_order.account_manager_id = data.account_manager_id

    // Set Media Ocean Meta Data
    if (data.media_ocean) {
      if (data.media_ocean.agency) {
        insertion_order.media_ocean.agency.id = data.media_ocean.agency.id ?? ''
        insertion_order.media_ocean.agency.name = data.media_ocean.agency.name ?? ''
        insertion_order.media_ocean.agency.address_line_1 = data.media_ocean.agency.address_line_1 ?? ''
        insertion_order.media_ocean.agency.address_line_2 = data.media_ocean.agency.address_line_2 ?? ''
        insertion_order.media_ocean.agency.address_line_3 = data.media_ocean.agency.address_line_3 ?? ''
        insertion_order.media_ocean.agency.address_line_4 = data.media_ocean.agency.address_line_4 ?? ''
      }
      if (data.media_ocean.station) {
        insertion_order.media_ocean.station.call_letters = data.media_ocean.station.call_letters ?? ''
      }
      if (data.media_ocean.header) {
        insertion_order.media_ocean.header.representative = data.media_ocean.header.representative ?? ''
        insertion_order.media_ocean.header.advertiser_name = data.media_ocean.header.advertiser_name ?? ''
        insertion_order.media_ocean.header.product_name = data.media_ocean.header.product_name ?? ''
        insertion_order.media_ocean.header.agency_estimate_code = data.media_ocean.header.agency_estimate_code ?? ''
        insertion_order.media_ocean.header.start_date = data.media_ocean.header.start_date ?? ''
        insertion_order.media_ocean.header.end_date = data.media_ocean.header.end_date ?? ''
        insertion_order.media_ocean.header.station_order_number = data.media_ocean.header.station_order_number ?? ''
        insertion_order.media_ocean.header.station_trade_order_number = data.media_ocean.header.station_trade_order_number ?? ''
        insertion_order.media_ocean.header.agency_advertiser_code = data.media_ocean.header.agency_advertiser_code ?? ''
        insertion_order.media_ocean.header.agency_product_code = data.media_ocean.header.agency_product_code ?? ''
      }

      if (data.media_ocean.demographic) {
        insertion_order.media_ocean.demographic.target = data.media_ocean.demographic.target ?? ''
        insertion_order.media_ocean.demographic.age_high = data.media_ocean.demographic.age_high ?? ''
        insertion_order.media_ocean.demographic.age_low = data.media_ocean.demographic.age_low ?? ''
      }

      if (data.media_ocean.isci_required) {
        insertion_order.media_ocean.isci_required = data.media_ocean.isci_required
      }

      if (data.media_ocean.has_cash_order) {
        insertion_order.media_ocean.has_cash_order = data.media_ocean.has_cash_order
      }

      if (data.media_ocean.has_trade_order) {
        insertion_order.media_ocean.has_trade_order = data.media_ocean.has_trade_order
      }
    }

    insertion_order._is_media_ocean_io = data.is_media_ocean_io

    if (data.sales_rep) insertion_order.sales_rep = User.toObject(data.sales_rep)

    if (data.items) {
      const current_items = InsertionOrderItem.module.data.filter(
        i => i.insertion_order_id == insertion_order.id,
      )

      if (current_items) {
        InsertionOrderItem.module.delete(current_items)
      }

      InsertionOrderItem.toObjectList(data.items)
    }

    if (
      insertion_order.is_media_ocean_io
      && !insertion_order.media_ocean.demographic.target
      && !insertion_order.media_ocean.demographic.age_high
      && !insertion_order.media_ocean.demographic.age_low
    ) {
      for (const i in insertion_order.items) {
        if (
          insertion_order.items[i].media_ocean.demographic.target
          && insertion_order.items[i].media_ocean.demographic.age_high
          && insertion_order.items[i].media_ocean.demographic.age_low
        ) {
          insertion_order.media_ocean.demographic.target = insertion_order.items[i].media_ocean.demographic.target
          insertion_order.media_ocean.demographic.age_high = insertion_order.items[i].media_ocean.demographic.age_high
          insertion_order.media_ocean.demographic.age_low = insertion_order.items[i].media_ocean.demographic.age_low
          break
        }
      }
    }

    insertion_order.agency_commission = data.agency_commission

    if (data.client) {
      Company.toObject(data.client)
    }

    if (data.agency) {
      Company.toObject(data.agency)
    }

    if (data.owner_company) {
      Company.toObject(data.owner_company)
    }

    if (data.owner) {
      User.toObject(data.owner)
    }

    if (data.account_manager) {
      User.toObject(data.account_manager)
    }

    //  Cache Object List
    if (cache) InsertionOrder.module.update(insertion_order)

    return insertion_order
  }

  public static toObjectList(data: any, cache: boolean = true): InsertionOrder[] {
    const insertion_orders = new Array<InsertionOrder>()
    data.forEach((value: any) => {
      const insertion_order = InsertionOrder.toObject(value, false)
      insertion_orders.push(insertion_order)
    })

    //  Cache Object List
    if (cache) InsertionOrder.module.update(insertion_orders)

    return insertion_orders
  }

  public toOption() {
    return {
      name: this.name,
      value: this.id,
    }
  }

  /// State Management
  public static get module(): InsertionOrderModule {
    if (!store.hasModule('insertion_order')) {
      store.registerModule('insertion_order', InsertionOrderModule)
    }

    return getModule(InsertionOrderModule)
  }

  public static find(id: string): InsertionOrder | null {
    const o = InsertionOrder.module.data.find(insertion_order => insertion_order.id === id)
    return o instanceof InsertionOrder ? o : null
  }

  public static filter(filter: string[]): InsertionOrder[] {
    if (Array.isArray(filter)) {
      return InsertionOrder.module.data.filter(
        insertion_order => insertion_order.id && filter.includes(insertion_order.id),
      )
    }
    return InsertionOrder.module.data.filter(filter)
  }

  public static async get(id: string): Promise<InsertionOrder | null> {
    return InsertionOrder.module.find(id)
  }

  public static async paginate(options: PaginateOptions) {
    return InsertionOrder.module.paginate(options)
  }

  public static getDeliveryReport(
    insertion_orders: string[],
    slides: string[],
    appendix: boolean,
    map: string,
    logos: string[],
  ) {
    const instance_id = getModule(SystemtModule)._uuid
    const api = new Api()
    return api.get('insertion_order/delivery-report', {
      instance_id,
      insertion_orders,
      slides,
      appendix,
      map,
      logos,
    })
  }

  public static export(insertion_orders: string[]) {
    const api = new Api()
    return api.get('insertion_order/export', {
      insertion_orders,
    })
  }
}
