






































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import InsertionOrder from '@/models/InsertionOrder'
import InsertionOrderHeaderProperty from './InsertionOrderHeaderProperty.vue'

@Component({
  components: {
    InsertionOrderHeaderProperty,
  },
})
export default class InsertionOrderPrintView extends Vue {
  @Prop()
  public insertion_order!: InsertionOrder;
}
