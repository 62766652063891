import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import InsertionOrder from '@/models/InsertionOrder'

const { user } = getModule(SystemtModule)

export default [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    show: true,
    type: 'checkbox',
  },
  {
    key: 'number',
    label: '#',
    sortable: true,
    show: true,
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    show: true,
  },
  {
    key: 'is_media_ocean_io',
    label: 'Media Ocean',
    sortable: true,
    show: true,
    type: 'boolean',
  },
  {
    key: 'type',
    label: 'Type',
    sortable: true,
    show: false,
    type: 'capitalize',
  },
  {
    key: 'agency_id',
    label: 'Agency',
    sortable: true,
    show: true,
    type: 'object',
    object: {
      key: 'agency',
      property: 'name',
    },
  },
  {
    key: 'client_id',
    label: 'Client',
    sortable: true,
    show: true,
    type: 'object',
    object: {
      key: 'client',
      property: 'name',
    },
  },
  {
    key: 'owner_id',
    label: 'AE',
    sortable: true,
    show: false,
    type: 'object',
    object: {
      key: 'owner',
      property: 'name',
    },
  },
  {
    key: 'account_manager_id',
    label: 'AM',
    sortable: true,
    show: true,
    type: 'object',
    object: {
      key: 'account_manager',
      property: 'name',
    },
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    show: true,
    type: 'badge',
    color: (io: InsertionOrder) => io.status_color,
    formatter: (value: any, key: string, insertion_order: InsertionOrder) =>
      insertion_order.status_human,
  },
  {
    key: 'media_ocean',
    label: 'Media Ocean',
    sortable: true,
    show: false,
    type: 'json',
    formatter: (value: any, key: string, insertion_order: InsertionOrder) => {
      if (insertion_order.is_media_ocean_io) {
        return insertion_order.media_ocean
      }
      return '-'
    },
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    show: true,
    type: 'date',
  },
  {
    key: 'updated_at',
    label: 'Update At',
    sortable: true,
    show: false,
    type: 'date',
  },
  {
    key: 'action',
    label: 'Actions',
    sortable: false,
    show: true,
    type: 'action_list',
    actions: [
      {
        icon: 'search',
        title: 'Show Details',
        event: 'details',
      },
      {
        icon: 'eye',
        title: 'View',
        event: 'view',
      },
      {
        icon: 'pencil',
        title: 'Edit',
        event: 'edit',
        show: () => user && user.can('insertion_order', 'edit'),
      },
      {
        icon: 'copy',
        title: 'Clone',
        event: 'clone',
        show: () => user && user.can('insertion_order', 'edit'),
      },
      {
        icon: 'file-export',
        title: 'Download Delivery Report',
        event: 'deliveryReport',
      },
      {
        icon: 'paper-plane',
        title: 'Send',
        event: 'send',
        show: (insertion_order: InsertionOrder) =>
          insertion_order.pdf_ready
          && !insertion_order.is_registered
          && !insertion_order.client_signed
          && !insertion_order.owner_signed,
      },
      {
        icon: 'bell',
        title: 'Send Reminder',
        event: 'sendReminder',
        show: (insertion_order: InsertionOrder) =>
          insertion_order.is_registered
          && (!insertion_order.client_signed || !insertion_order.owner_signed),
      },
      {
        icon: 'times',
        title: 'Cancel',
        event: 'cancel',
        show: (insertion_order: InsertionOrder) =>
          insertion_order.is_registered
          && (!insertion_order.client_signed || !insertion_order.owner_signed),
      },
      {
        icon: 'download',
        title: 'Download IO',
        event: 'download',
        show: (insertion_order: InsertionOrder) => insertion_order.pdf_ready,
      },
      {
        icon: 'file-invoice-dollar',
        title: 'Download Cash Order PDF',
        event: 'downloadCashOrder',
        show: (insertion_order: InsertionOrder) =>
          insertion_order.is_media_ocean_io && insertion_order.media_ocean.has_cash_order,
      },
      {
        icon: 'file-invoice',
        title: 'Download Trade Order PDF',
        event: 'downloadTradeOrder',
        show: (insertion_order: InsertionOrder) =>
          insertion_order.is_media_ocean_io && insertion_order.media_ocean.has_trade_order,
      },
      {
        icon: 'upload',
        title: 'Upload IO',
        event: 'upload',
        show: (insertion_order: InsertionOrder) =>
          !insertion_order.client_signed && !insertion_order.owner_signed,
      },
      {
        icon: 'trash',
        title: 'Delete',
        event: 'delete',
        show: (insertion_order: InsertionOrder) => user && user.can('insertion_order', 'delete'),
      },
    ],
  },
]
